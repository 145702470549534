import type { FunctionComponent } from "react";

import { Button } from "@homescan/ui/components/Button";
import { ContactModal } from "@homescan/ui/components/ContactModal";
import { from } from "@homescan/ui/styles/responsiveness";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  padding: ${({ theme }) => theme.spacing.ss2};
  width: 100%;
  border-top-left-radius: ${({ theme }) => theme.radius.rad4};
  border-top-right-radius: ${({ theme }) => theme.radius.rad4};

  ${from("desktop")} {
    padding: ${({ theme }) => `${theme.spacing.ss3} ${theme.spacing.ss7}`};
  }

  ${from("desktopFullHD")} {
    margin: ${({ theme }) => `${theme.spacing.ss0} auto`};
    max-width: calc(1408px + 104px);
  }
`;

const FooterSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.ss2};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.misc.border.normal};
  }
`;

const FooterText = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.fs3};

  br {
    ${from("tablet")} {
      display: none;
    }
  }
`;

const CopyrightYear = styled.span`
  color: ${({ theme }) => theme.colors.primary.default};
  margin: ${({ theme }) => `${theme.spacing.ss0} 4px`};
`;

const FooterNavigation = styled.nav``;

const FooterNavigationButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.ss0};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const YEAR = new Date().getFullYear();

export const Footer: FunctionComponent = () => (
  <FooterWrapper>
    <FooterSection>
      <FooterText>
        Homescan.pl
        <CopyrightYear>{YEAR}</CopyrightYear>© <br />
        Made with ❤️
      </FooterText>
      <FooterNavigation>
        <ContactModal>
          {({ handleToggleModal }) => (
            <FooterNavigationButton title="Kontakt" onClick={handleToggleModal} />
          )}
        </ContactModal>
      </FooterNavigation>
    </FooterSection>
  </FooterWrapper>
);
