import { FunctionComponent } from "react";

import {
  Activity,
  Archive,
  BarChart2,
  Bell,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Coffee,
  Facebook,
  Filter,
  Heart,
  Home,
  Link,
  Loader,
  LogIn,
  Mail,
  Map,
  MapPin,
  Pause,
  PenTool,
  Play,
  Plus,
  Rss,
  Save,
  Search,
  Trash2,
  Twitter,
  Type,
  X,
} from "@styled-icons/feather";

export const icons = {
  Activity,
  Archive,
  BarChart2,
  Bell,
  Coffee,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Facebook,
  Filter,
  Heart,
  Home,
  Link,
  Loader,
  LogIn,
  Mail,
  Map,
  MapPin,
  Pause,
  PenTool,
  Play,
  Plus,
  Rss,
  Save,
  Search,
  Trash2,
  Twitter,
  Type,
  X,
} as const;

export type IconName = keyof typeof icons;

type IconProps = {
  name: IconName;
  className?: string;
  size?: number;
  title?: string;
};

export const Icon: FunctionComponent<IconProps> = ({
  name,
  size = 22,
  title = "Icon",
  className,
}) => {
  const Component = icons[name];
  return <Component size={size} title={title} className={className} />;
};
