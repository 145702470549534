import { type FunctionComponent, useState } from "react";

import { AnimatePresence, type AnimationProps, motion } from "framer-motion";
import styled from "styled-components";

import { useCopyToClipboard } from "../../utils/hooks/useCopyToClipboard";
import { SecondaryButton } from "./Button";

const CopyButton = styled(SecondaryButton)`
  width: 160px;
`;

const animationVariants = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
};

const animationProps: AnimationProps = {
  variants: animationVariants,
  initial: "initial",
  animate: "animate",
  exit: "initial",
  transition: { duration: 0.2 },
};

type CopyToClipboardButtonProps = {
  value: string;
};

export const CopyToClipboardButton: FunctionComponent<CopyToClipboardButtonProps> = ({ value }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { handleCopyToClipboard } = useCopyToClipboard();

  const handleCopyUrlToClipboard = () => {
    handleCopyToClipboard(value);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <CopyButton onClick={handleCopyUrlToClipboard}>
      <AnimatePresence mode="wait" initial={false}>
        {isCopied ? (
          <motion.span {...animationProps} key="copied">
            Skopiowano adres
          </motion.span>
        ) : (
          <motion.span {...animationProps} key="toCopy">
            Skopiuj adres do schowka
          </motion.span>
        )}
      </AnimatePresence>
    </CopyButton>
  );
};
