import type { FunctionComponent } from "react";

import { Image } from "@homescan/ui/components/Image";
import { Link } from "@homescan/ui/components/Link";
import styled from "styled-components";

import LogoSVG from "public/logo.svg";
import { routes } from "utils/routes";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImage = styled(Image)`
  margin-right: ${({ theme }) => theme.spacing.ss1};
`;

const LogoTitle = styled.h1`
  line-height: 40px;
  font-size: ${({ theme }) => theme.fontSize.fs4};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const LogoTitleSmallPart = styled(LogoTitle)`
  position: relative;
  top: 3px;
  font-size: ${({ theme }) => theme.fontSize.fs3};
`;

type LogoProp = {
  className?: string;
};

export const Logo: FunctionComponent = ({ className }: LogoProp) => (
  <LogoWrapper className={className} data-testid="homescanLogoTestId">
    <Link href={routes.landing} shallow>
      <LogoImage alt="homescan.plLogo" src={LogoSVG} width={40} height={40} priority />
      <LogoTitle>Homescan</LogoTitle>
      <LogoTitleSmallPart>.pl</LogoTitleSmallPart>
    </Link>
  </LogoWrapper>
);
