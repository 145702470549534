import type { FunctionComponent } from "react";

import styled from "styled-components";

import { H1, Section } from "../Primitives";
import { MODAL_VARIANT } from "./constants";
import { Modal, type ModalProps } from "./Modal";
import { ModalVariant } from "./types";

const StandardModalWrapper = styled(Modal)``;

const ModalTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss3}`};
  padding-bottom: ${({ theme }) => theme.spacing.ss2};
  padding: ${({ theme }) => `${theme.spacing.ss3} ${theme.spacing.ss0} ${theme.spacing.ss2}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.misc.border.normal};
  z-index: ${({ theme }) => theme.zIndex.modalHeading};

  ${H1} {
    font-size: ${({ theme }) => theme.fontSize.fs4};
    padding-right: ${({ theme }) => theme.spacing.ss4};
  }
`;

type ModalBodyStyleProps = {
  variant?: ModalVariant;
};

const ModalBody = styled(Section)<ModalBodyStyleProps>`
  position: unset;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ variant, theme }) =>
    variant === MODAL_VARIANT.NORMAL
      ? `
    margin: ${`${theme.spacing.ss9} ${theme.spacing.ss3}`};
    padding: ${`${theme.spacing.ss3} ${theme.spacing.ss0} ${theme.spacing.ss8}`};
    overflow-y: scroll;
  `
      : `
    margin: ${`${theme.spacing.ss9} ${theme.spacing.ss3} ${theme.spacing.ss3}`};
    padding: ${`${theme.spacing.ss3} ${theme.spacing.ss0} ${theme.spacing.ss4}`};
  `}
`;

export const StandardModalBaseComponents = {
  ModalTitle,
  ModalBody,
};

type StandardModalProps = {
  /**
   * @description Modal title
   */
  title?: string;
} & ModalProps;

/**
 * @description Standard modal with default layout.
 */
export const StandardModal: FunctionComponent<StandardModalProps> = ({
  title,
  children,
  variant,
  ...props
}) => (
  <StandardModalWrapper {...props} variant={variant}>
    {title && (
      <ModalTitle>
        <H1 isTruncated>{title}</H1>
      </ModalTitle>
    )}
    <ModalBody variant={variant}>{children}</ModalBody>
  </StandardModalWrapper>
);
