import { useState } from "react";

type CopiedValue = string | null;
type CopyToClipboardHandler = (text: string) => Promise<boolean>;

type UseCopyToClipboardReturn = {
  copiedValue: CopiedValue;
  handleCopyToClipboard: CopyToClipboardHandler;
};

export const useCopyToClipboard = (): UseCopyToClipboardReturn => {
  const [copiedValue, setCopiedText] = useState<CopiedValue>(null);

  const handleCopyToClipboard: CopyToClipboardHandler = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return {
    copiedValue,
    handleCopyToClipboard,
  };
};
