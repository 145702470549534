export const spacing = {
  ss0: "0px",
  ss1: "8px",
  ss2: "16px",
  ss3: "24px",
  ss4: "32px",
  ss5: "40px",
  ss6: "48px",
  ss7: "56px",
  ss8: "64px",
  ss9: "72px",
  ss10: "96px",
  ss11: "120px",
} as const;

export const fontSize = {
  fs1: "12px",
  fs2: "14px",
  /**
   * @description Default font-size
   */
  fs3: "16px",
  fs4: "22px",
  fs5: "32px",
  fs6: "48px",
} as const;

export const fontWeight = {
  thin: 200,
  regular: 400,
  bold: 600,
} as const;

export const zIndex = {
  behindInitial: -1,
  initial: 1,
  onTopOfInitial: 2,
  topMenu: 48,
  sidebarExpanded: 49,
  sidebar: 50,
  modal: 60,
  modalHeading: 61,
  modalFooter: 61,
  tooltip: 70,
  overlay: 99,
  toast: 100,
} as const;

export const radius = {
  rad1: "8px",
  rad2: "16px",
  rad3: "24px",
  rad4: "32px",
  /**
   * @description Round radius
   */
  rad5: "50%",
} as const;

const getOverlayLinearGradient = (deg = 0) =>
  `linear-gradient(${deg}deg, #242424 0%, rgba(40, 40, 40, 0) 100%)`;

export const colors = {
  // primary
  primary: {
    default: "#FA7C91",
    shadow: "#C55A67",
  },
  // secondary
  secondary: {
    default: "#968D8D",
    shadow: "#635B5B",
  },
  // tertiary
  tertiary: {
    default: "#4D4949",
    shadow: "#4D4949",
  },
  // success
  success: {
    default: "#33AB53",
    shadow: "#237A3B",
  },
  // error
  error: {
    default: "#C3475C",
    shadow: "#903242",
  },
  // background
  background: {
    primary: "#282828",
    secondary: "#1D1D1D",
    tertiary: "#53535B",
    quaternary: "#2E2E2E",
    quinary: "#393939",
  },
  // text
  text: {
    primary: "#FAFAFC",
    secondary: "#BCBCBD",
    tertiary: "#858585",
  },
  // rest
  misc: {
    border: {
      light: "#2d2e30",
      normal: "#464646",
    },
    donate: "#ffdd00",
    overlay: "#0F0F0F4D",
    offerOverlay: "#0F0F0F99",
    transparentBackground: "#2E2E2EEB",
    bottomOverlay: getOverlayLinearGradient(),
    topRightOverlay: getOverlayLinearGradient(225),
    skeleton: {
      primary: "#1D1D1DCC",
      secondary: "#222222",
      tertiary: "#2B2B2E",
    },
  },
} as const;

/**
 * @description Color for box-shadows in HSL
 */
const BOX_SHADOW_COLOR = "#0505052B";
/**
 * @description We divide the box shadows by elevations
 * Standard box-shadow color is: hsl(0deg 0% 50%)
 */
export const boxShadows = {
  shadow: (color: string) => `0 4px 24px 0 ${color}`,
  small: `0px 2px 6px 2px ${BOX_SHADOW_COLOR}`,
  medium: `0px 3px 7px 3px ${BOX_SHADOW_COLOR}`,
  large: `0px 4px 8px 4px ${BOX_SHADOW_COLOR}`,
} as const;

const SIDEBAR_WIDTH = "80px";

export const width = {
  sidebar: SIDEBAR_WIDTH,
  sidebarWithMargin: `calc(${SIDEBAR_WIDTH} + ${spacing.ss1})`,
} as const;
