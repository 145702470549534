import { type FunctionComponent, useState } from "react";

import styled from "styled-components";

import { MODAL_VARIANT, StandardModal } from "../Modal";
import { EmailAddressInfo } from "./components/EmailAddressInfo";

const ContactModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.ss4};
  align-items: center;
  justify-content: center;
`;

const ContactModalContent: FunctionComponent = () => (
  <ContactModalContentWrapper>
    <EmailAddressInfo label="Pomoc" email="kontakt@homescan.pl" emailSubject="Pomoc" />
    <EmailAddressInfo
      label="Zarządzanie pakietami"
      email="premium@homescan.pl"
      emailSubject="Premium"
    />
  </ContactModalContentWrapper>
);

type ChildrenRenderProps = {
  handleToggleModal: () => void;
};

type ContactModalProps = {
  children: (props: ChildrenRenderProps) => void;
};

export const ContactModal: FunctionComponent<ContactModalProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <StandardModal
        variant={MODAL_VARIANT.SMALL}
        isOpen={isModalOpen}
        onClose={handleToggleModal}
        title="Kontakt"
      >
        <ContactModalContent />
      </StandardModal>
      {children({ handleToggleModal })}
    </>
  );
};
