import { type ReactElement, type ReactNode } from "react";

import type { NextPage } from "next";
import type { AppProps as NextAppProps } from "next/app";

import { Layout } from "components/Layout";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppProps = NextAppProps & {
  Component: NextPageWithLayout;
};

const HomescanLandingApp = ({ Component, pageProps }: AppProps) => (
  <Layout>
    <Component {...pageProps} />
  </Layout>
);

export default HomescanLandingApp;
