import { FunctionComponent, MouseEventHandler, ReactNode, useEffect, useRef } from "react";

import { AnimatePresence, type AnimationProps, motion, type Transition } from "framer-motion";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { useRootElement } from "../../utils/hooks/useRootElement";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { MODAL_VARIANT } from "./constants";
import type { ModalVariant } from "./types";

const transition: Transition = {
  duration: 0.2,
};

const ModalBackgroundAnimationProps: AnimationProps = {
  variants: {
    initial: {
      opacity: 0,
      backdropFilter: "blur(0px)",
      transition,
    },
    animate: {
      opacity: 1,
      backdropFilter: "blur(4px)",
      transition,
    },
  },
  initial: "initial",
  animate: "animate",
  exit: "initial",
};

const ModalBackground = styled(motion.div).attrs(ModalBackgroundAnimationProps)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.misc.overlay};
  z-index: ${({ theme }) => theme.zIndex.modal};
  will-change: backdrop-filter, opacity;
`;

const ModalContainerAnimationProps: AnimationProps = {
  variants: {
    initial: {
      opacity: 0,
      y: 80,
      transition,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition,
    },
  },
  initial: "initial",
  animate: "animate",
  exit: "initial",
};

type ModalContainerStyleProps = {
  variant: ModalVariant;
};

const ModalContainer = styled(motion.div).attrs(
  ModalContainerAnimationProps,
)<ModalContainerStyleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(80px);
  background-color: ${({ theme }) => theme.colors.misc.transparentBackground};
  border-radius: ${({ theme }) => theme.radius.rad3};
  overflow: hidden;
  will-change: transform, opacity;

  ${({ variant }) =>
    variant === MODAL_VARIANT.NORMAL
      ? `
      width: 640px;
      min-height: 280px;
      max-height: 100%;
  `
      : `
      width: 460px;
      min-height: 280px;
      max-height: 100%;
  `}
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.ss3};
  right: ${({ theme }) => theme.spacing.ss3};
  padding: ${({ theme }) => theme.spacing.ss0};
  z-index: ${({ theme }) => theme.zIndex.modalHeading};
`;

export const ModalBaseComponents = {
  ModalBackground,
  ModalContainer,
  CloseButton,
};

export type ModalProps = {
  className?: string;
  /**
   * @description Modal open state
   */
  isOpen: boolean;
  /**
   * @description On modal close handler
   */
  onClose?: () => void;
  /**
   * @description Modal content
   */
  children: ReactNode;
  variant?: ModalVariant;
};

const ModalBase: FunctionComponent<ModalProps> = ({
  className,
  isOpen,
  variant = MODAL_VARIANT.NORMAL,
  children,
  onClose,
}) => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.();
      }
    };

    if (isOpen) {
      // Prevent body scroll when the modal is open
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeydown);
    }

    return () => {
      if (isOpen) {
        document.body.style.overflow = "unset";
        document.removeEventListener("keydown", handleKeydown);
      }
    };
  }, [isOpen, onClose]);

  const handleBackgroundClick: MouseEventHandler<HTMLDivElement> = (event) =>
    backgroundRef.current === event.target && onClose?.();

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalBackground key="modalBackground" onClick={handleBackgroundClick} ref={backgroundRef}>
          <ModalContainer key="modalContainer" className={className} variant={variant}>
            <>
              {children}
              {onClose && (
                <CloseButton onClick={onClose}>
                  <Icon name="X" size={24} />
                </CloseButton>
              )}
            </>
          </ModalContainer>
        </ModalBackground>
      )}
    </AnimatePresence>
  );
};

export const Modal: FunctionComponent<ModalProps> = (props) => {
  const wrapper = useRootElement("modals");

  return wrapper ? createPortal(<ModalBase {...props} />, wrapper) : null;
};
