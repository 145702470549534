import type { FunctionComponent, ReactNode } from "react";

import styled from "styled-components";

const ModalActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.spacing.ss2} ${theme.spacing.ss3}`};
  background: ${({ theme }) => theme.colors.misc.bottomOverlay};
`;

type ModalActionsProps = {
  className?: string;
  children: ReactNode;
};

export const ModalActions: FunctionComponent<ModalActionsProps> = ({ className, children }) => (
  <ModalActionsWrapper className={className}>{children}</ModalActionsWrapper>
);
