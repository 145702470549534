/**
 * @description Check if the current env is development.
 */
export const isDev = () => process.env.NODE_ENV !== "production";

const devices = {
  browser: "browser",
  server: "server",
} as const;

/* istanbul ignore next */
const canUseDOM = !!(typeof window !== "undefined" && window.document?.createElement);
/* istanbul ignore next */
const device = canUseDOM ? devices.browser : devices.server;

/**
 * @description Check if the current device is a browser.
 */
export const isBrowser = () => device === devices.browser;

/**
 * @description Check if the current device is a server.
 */
export const isServer = () => device === devices.server;

/**
 * @description Check if the client device is mobile
 */
export const isMobile = () => isBrowser() && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
