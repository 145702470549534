import { FunctionComponent } from "react";

import styled from "styled-components";

import { Link } from "../../Link";
import { H5 } from "../../Primitives";

const EmailAddressInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss2};
`;

const EmailAddressInfoLabel = styled(H5)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
`;

const EmailAddress = styled(Link)``;

type EmailAddressInfoProps = {
  label: string;
  email: string;
  emailSubject?: string;
};

export const EmailAddressInfo: FunctionComponent<EmailAddressInfoProps> = ({
  label,
  email,
  emailSubject,
}) => (
  <EmailAddressInfoWrapper>
    <EmailAddressInfoLabel>{label}</EmailAddressInfoLabel>
    <EmailAddress href={`mailto:${email}${emailSubject ? `?subject=${emailSubject}` : ""}`}>
      {email}
    </EmailAddress>
  </EmailAddressInfoWrapper>
);
