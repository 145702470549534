import type { FunctionComponent } from "react";

import { from } from "@homescan/ui/styles";
import styled from "styled-components";

import { Logo } from "./components/Logo";

const TopMenuWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => `${theme.spacing.ss2} ${theme.spacing.ss2}`};

  ${from("desktop")} {
    display: flex;
    margin: ${({ theme }) => `${theme.spacing.ss7} ${theme.spacing.ss7}`};
  }
`;

export const TopMenu: FunctionComponent = () => (
  <TopMenuWrapper>
    <Logo />
  </TopMenuWrapper>
);
