import { MutableRefObject, useEffect, useRef } from "react";

import { isBrowser } from "../../env";

type HTMLElRef = MutableRefObject<HTMLElement>;

const createRootElement = (id: string) => {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
};

const addRootElement = (rootElem: Node) => {
  document.body.appendChild(rootElem);
};

export const useRootElement = (id: string): HTMLElement => {
  const rootElemRef = useRef(
    isBrowser() ? document.createElement("div") : /* istanbul ignore next */ null,
  ) as HTMLElRef;

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElem);
    }

    parentElem.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      /* istanbul ignore if */
      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
  }, [id, rootElemRef]);

  const getRootElem = () => {
    /* istanbul ignore if */
    if (!rootElemRef.current && /* istanbul ignore next */ isBrowser()) {
      rootElemRef.current = document.createElement("div");
    }
    return rootElemRef.current;
  };

  return getRootElem();
};
