import styled from "styled-components";

import { from } from "../../styles/responsiveness";

type HorizontalScrollListStyleProps = {
  /**
   * @description Indicate if the list should have a scroll snap
   */
  withScrollSnap?: boolean;
};

export const HorizontalScrollList = styled.ul<HorizontalScrollListStyleProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.ss4};
  list-style: none;
  overflow-x: scroll;

  ${({ withScrollSnap, theme }) =>
    withScrollSnap &&
    `
      padding: ${`${theme.spacing.ss0} ${theme.spacing.ss2}`};
      /* scroll */
      overscroll-behavior-inline: contain;
      scroll-padding-left: ${theme.spacing.ss2};
      scroll-snap-type: x mandatory;
      /* disable scrollbar */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        scroll-snap-align: start;
      }

      ${from("tablet")} {
        scroll-padding-left: ${theme.spacing.ss4};
        padding: ${`${theme.spacing.ss0} ${theme.spacing.ss4}`};
      }

      ${from("desktop")} {
        scroll-padding-left: ${theme.spacing.ss6};
        padding: ${`${theme.spacing.ss0} ${theme.spacing.ss6}`};
      }
  `}
`;

export const HorizontalScrollListItem = styled.li`
  flex: none;
`;
