const MOBILE_BREAKPOINT = "768px";
const TABLET_BREAKPOINT = "768px";
const DESKTOP_BREAKPOINT = "1024px";
const DESKTOP_FULLHD_BREAKPOINT = "1408px";
export const HIDE_FILTERS_THRESHOLD = "1196px";

export const breakpoints = {
  mobile: MOBILE_BREAKPOINT,
  tablet: TABLET_BREAKPOINT,
  desktop: DESKTOP_BREAKPOINT,
  desktopFullHD: DESKTOP_FULLHD_BREAKPOINT,
  hideFilters: HIDE_FILTERS_THRESHOLD,
} as const;

// app.homescan.pl is mobile-first

type BreakpointName = keyof typeof breakpoints;

/**
 * @description Available breakpoints:
 * - mobile up to 768px
 * - tablet from 768px
 * - desktop from 1024px
 * - desktop FullHD from 1408px
 * @param name - breakpoint name
 * @returns CSS media
 */
export const upTo = (name: BreakpointName) => `
  @media (max-width: ${breakpoints[name]})
`;

/**
 * @description Available breakpoints:
 * - mobile up to 768px
 * - tablet from 768px
 * - desktop from 1024px
 * - desktop FullHD from 1408px
 * @param name - breakpoint name
 * @returns CSS media
 */
export const from = (name: BreakpointName) => `
  @media (min-width: ${breakpoints[name]})
`;

/**
 * @description Available breakpoints:
 * - mobile up to 768px
 * - tablet from 768px
 * - desktop from 1024px
 * - desktop FullHD from 1408px
 * @param minName - breakpoint name
 * @param maxName - breakpoint name
 * @returns CSS media
 */
export const between = /* istanbul ignore next */ (
  minName: BreakpointName,
  maxName: BreakpointName,
) => `
  @media (min-width: ${breakpoints[minName]}) and (max-width: ${breakpoints[maxName]})
`;
