import type { FunctionComponent, PropsWithChildren } from "react";

import NextLink from "next/link";
import styled, { css, DefaultTheme, StyledComponent } from "styled-components";
import type { UrlObject } from "url";

import type { RequireOnlyOne } from "../../utils/utilityTypes";

const LinkStyles = css`
  display: flex;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSize.fs3};
  width: max-content;
  transition: transform 0.3s, opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.99);
  }
`;

const StyledLink = styled(NextLink)`
  ${LinkStyles}
`;

const DisabledLink = styled.a`
  ${LinkStyles}
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    color: inherit;
  }
`;

type LinkPropsBase = PropsWithChildren<{
  className?: string;
  testid?: string;
  disabled?: boolean;
  // Check the shallow because it is broken
  shallow?: boolean;
  title: string;
  href: UrlObject | string;
}>;

type LinkProps = RequireOnlyOne<LinkPropsBase, "children" | "title">;

export const Link: FunctionComponent<LinkProps> = ({
  disabled,
  testid,
  className,
  href,
  title,
  children,
}) => {
  if (disabled) {
    return (
      <DisabledLink className={className} data-testid={testid}>
        {children || title}
      </DisabledLink>
    );
  }

  return (
    <StyledLink href={href} className={className} data-testid={testid}>
      {children || title}
    </StyledLink>
  );
};

export const ExternalLink = styled(StyledLink).attrs({
  target: "_blank",
  rel: "noopener",
})`
  color: ${({ theme }) => theme.colors.text.primary};
`;

type LinkType = "primary" | "secondary" | "tertiary";

type LinkStyleProps = {
  shadow?: boolean;
};

const createLink = (
  type: LinkType,
  Component: typeof Link | typeof ExternalLink,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StyledComponent<FunctionComponent<any>, DefaultTheme, LinkStyleProps, never> => styled(
  Component,
)<LinkStyleProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors[type].default};
  border-radius: ${({ theme }) => theme.radius.rad2};
  text-align: center;
  padding: ${({ theme }) => `${theme.spacing.ss1} ${theme.spacing.ss2}`};

  ${({ shadow, theme }) =>
    shadow &&
    `
    box-shadow: ${theme.boxShadows.shadow(theme.colors[type].shadow)}
  `}
`;

export const PrimaryLink = createLink("primary", Link);

export const PrimaryExternalLink = createLink("primary", ExternalLink);

export const SecondaryLink = createLink("secondary", Link);

export const SecondaryExternalLink = createLink("secondary", ExternalLink);

export const TertiaryLink = createLink("tertiary", Link);

export const TertiaryExternalLink = createLink("tertiary", ExternalLink);
