import { FunctionComponent, MouseEventHandler, PropsWithChildren } from "react";

import styled from "styled-components";

import { RequireOnlyOne } from "../../utils/utilityTypes";

const StyledButton = styled.button`
  display: inline-block;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: transparent;
  border: none;
  padding: ${({ theme }) => `${theme.spacing.ss1} ${theme.spacing.ss2}`};
  font-size: ${({ theme }) => theme.fontSize.fs3};
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
  outline: none;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

type ButtonPropsBase = PropsWithChildren<{
  className?: string;
  testid?: string;
  title: string;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  onClick?: () => void;
}>;

export type ButtonProps = RequireOnlyOne<ButtonPropsBase, "children" | "title">;

export const Button: FunctionComponent<ButtonProps> = ({
  disabled,
  onClick,
  testid,
  className,
  type = "button",
  children,
  title,
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!disabled && onClick) {
      event.preventDefault();
      return onClick();
    }
    return null;
  };

  return (
    <StyledButton
      data-testid={testid}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      type={type}
    >
      {children || title}
    </StyledButton>
  );
};

type ButtonStyleProps = {
  shadow?: boolean;
};

type ButtonType = "primary" | "secondary" | "tertiary" | "error";

const createButton = (type: ButtonType) => styled(Button)<ButtonStyleProps>`
  height: 40px;
  background-color: ${({ theme }) => theme.colors[type].default};
  border-radius: ${({ theme }) => theme.radius.rad2};

  ${({ shadow, theme }) =>
    shadow &&
    `
    box-shadow: ${theme.boxShadows.shadow(theme.colors[type].shadow)}
`}
`;

export const PrimaryButton = createButton("primary");

export const SecondaryButton = createButton("secondary");

export const TertiaryButton = createButton("tertiary");

export const DangerButton = createButton("error");
