import {
  boxShadows,
  colors,
  fontSize,
  fontWeight,
  radius,
  spacing,
  width,
  zIndex,
} from "./variables";

export const theme = {
  colors,
  fontSize,
  zIndex,
  spacing,
  radius,
  boxShadows,
  fontWeight,
  width,
};
