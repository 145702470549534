import styled from "styled-components";

import { column, gridRow } from "../../styles/grid";
import { from } from "../../styles/responsiveness";

export const UnorderedList = styled.ul`
  ${gridRow()}
  list-style: none;
  justify-content: space-between;
`;

type UnorderedListItemProps = {
  size?: number;
};

export const UnorderedListItem = styled.li<UnorderedListItemProps>`
  ${column(4)}

  ${from("tablet")} {
    ${column(4)}
  }

  ${from("desktop")} {
    ${column(4)}
  }

  ${from("desktopFullHD")} {
    ${column(4)}
  }
`;
