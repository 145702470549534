import {
  forwardRef,
  type ForwardRefRenderFunction,
  MouseEventHandler,
  type ReactNode,
} from "react";

import styled, { css } from "styled-components";

import { from } from "../../styles/responsiveness";
import { H2 } from "./Headings";

export const SectionMarginsCSS = css`
  margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss2}`};

  ${from("tablet")} {
    margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss4}`};
  }

  ${from("desktop")} {
    margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss6}`};
  }
`;

const SectionWrapper = styled.section`
  ${SectionMarginsCSS}
  position: relative;

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing.ss4} !important;
  }
`;

const SectionHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.ss1};

  ${H2} {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: ${({ theme }) => theme.fontSize.fs4};
  }

  ${from("desktop")} {
    margin-bottom: ${({ theme }) => theme.spacing.ss2};
  }
`;

const SectionActions = styled.div`
  margin-left: ${({ theme }) => theme.spacing.ss1};
`;

export const SectionBaseComponents = {
  SectionHeading,
  SectionActions,
};

type SectionProps = {
  className?: string;
  /**
   * @description Section title
   */
  title?: ReactNode;
  /**
   * @description Section actions
   */
  actions?: ReactNode;
  /**
   * @description Section content
   */
  children: ReactNode;
  id?: string;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
};

const SectionBase: ForwardRefRenderFunction<HTMLElement, SectionProps> = (
  { title, children, className, id, actions, onMouseEnter, onMouseLeave },
  ref,
) => (
  <SectionWrapper
    ref={ref}
    className={className}
    id={id}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {title && (
      <SectionHeading>
        {typeof title === "string" ? <H2>{title}</H2> : title}
        {actions && <SectionActions>{actions}</SectionActions>}
      </SectionHeading>
    )}
    {children}
  </SectionWrapper>
);

export const Section = forwardRef(SectionBase);
