import type { FunctionComponent, ReactNode } from "react";

import { Image } from "@homescan/ui/components/Image";
import { Normalize, theme } from "@homescan/ui/styles";
import { from } from "@homescan/ui/styles/responsiveness";
import { Montserrat } from "next/font/google";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

import { Footer } from "components/Footer";
import { TopMenu } from "components/TopMenu";
import Background from "public/heroBackground.webp";

const montserrat = Montserrat({
  display: "swap",
  subsets: ["latin"],
  style: ["normal"],
  weight: ["200", "400", "600"],
});

/* istanbul ignore next */
const GlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.colors.text.primary};
    font-family: ${montserrat.style.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.fs3};
  }

  body {
    background-color: ${({ theme }) => theme.colors.background.primary};

    #__next {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
  }

  #layers {
    z-index: ${({ theme }) => theme.zIndex.tooltip};
  }
`;

const LayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing.ss2};
  padding-bottom: ${({ theme }) => theme.spacing.ss6};

  ${from("desktop")} {
    padding-top: ${({ theme }) => theme.spacing.ss4};
    padding-bottom: ${({ theme }) => theme.spacing.ss8};
  }

  ${from("desktopFullHD")} {
    margin: ${({ theme }) => `${theme.spacing.ss0} auto`};
    width: 100%;
    max-width: 1408px;
  }
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  filter: blur(10px);
  z-index: ${({ theme }) => theme.zIndex.behindInitial};
`;

type LayoutProps = {
  children: ReactNode;
};

export const Layout: FunctionComponent<LayoutProps> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Normalize />
    <TopMenu />
    <LayoutMain>
      <BackgroundImage alt="Background" src={Background} placeholder="blur" priority fill />
      {children}
    </LayoutMain>
    <Footer />
  </ThemeProvider>
);
