// eslint-disable-next-line import/no-extraneous-dependencies
import { css, FlattenSimpleInterpolation } from "styled-components";

import { from } from "./responsiveness";
import { spacing } from "./variables";

/**
 * @description Creates grid with variable columns numbers
 * Tries to fit as much columns as possible
 * Use minWidth to create a point where column is too small
 * and needs to be moved to next row
 * This is best for gallery of items with the same size
 * There is no need for column() mixing when using autoGrid.
 */
export const autoGrid = (minWidth?: string): FlattenSimpleInterpolation => css`
  display: grid;
  grid-gap: ${spacing.ss4};
  grid-template-columns: repeat(auto-fit, minmax(${minWidth || "150px"}, 1fr));
`;

/**
 * @description Creates grid with at least one row
 * Though if you provide two items that are equal or wider than max row width
 * (e.g. two items with 12 columns on desktop will render in two full width rows)
 */
export const gridRow = (): FlattenSimpleInterpolation => css`
  display: grid;
  grid-gap: ${spacing.ss4};
  grid-template-columns: repeat(4, minmax(0, 1fr));

  ${from("tablet")} {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  ${from("desktop")} {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
`;

export const column = (size: number, start?: number): FlattenSimpleInterpolation => css`
  grid-column: ${start ? `${start} /` : ""} span ${size};
`;

/**
 * @description This works only with even size numbers
 */
export const centeredColumn = (size: number): FlattenSimpleInterpolation =>
  column(size, (12 - size) / 2);
