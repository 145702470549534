import styled, { css } from "styled-components";

import { from } from "../../styles";

const HeadingsGradientColorStyles = css`
  background-image: ${({ theme }) =>
    `-webkit-linear-gradient(48deg, ${theme.colors.text.primary} 5%, ${theme.colors.primary.default} 50%, ${theme.colors.primary.default} 70%, ${theme.colors.text.primary} 94%)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

type CommonStylesProps = {
  isTruncated?: boolean;
};

const commonStyles = css<CommonStylesProps>`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  word-break: break-word;

  ${({ isTruncated }) =>
    isTruncated &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  `}
`;

type H1StyleProps = CommonStylesProps & {
  withGradientColor?: boolean;
};

export const H1 = styled.h1<H1StyleProps>`
  ${commonStyles}
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSize.fs4};

  ${from("tablet")} {
    font-size: ${({ theme }) => theme.fontSize.fs5};
  }

  ${({ withGradientColor }) => withGradientColor && HeadingsGradientColorStyles}
`;

export const H2 = styled.h2`
  ${commonStyles}
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.fs3};

  ${from("tablet")} {
    font-size: ${({ theme }) => theme.fontSize.fs3};
  }
`;

export const H3 = styled.h3`
  ${commonStyles}
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSize.fs3};
`;

export const H4 = styled.h4`
  ${commonStyles}
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.fontSize.fs2};
`;

export const H5 = styled.h5`
  ${commonStyles}
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.fs2};
`;
